import React from "react";

const TopBanner = () => {
  return (
    <div className="bg-face-softCoral text-white py-2 px-4 text-center mt-8">
      <p className="text-sm font-medium">
        Get advice on the best products available for you!
      </p>
    </div>
  );
};

export default TopBanner;
